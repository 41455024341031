<template>
  <div class="app-container">
    <div class="filter-container">
      <el-input
        v-model="listQuery.keyword"
        placeholder="请输入关键字"
        style="width: 200px"
        class="filter-item"
        clearable
      />
      <el-select
        v-model="listQuery.category"
        placeholder="设备类别"
        style="width: 120px"
        class="filter-item"
        clearable
      >
        <el-option
          v-for="item in typeOption"
          :key="item.id"
          :label="item.name"
          :value="item.id"
        />
      </el-select>
      <el-button
        class="filter-item"
        type="primary"
        icon="el-icon-search"
        @click="handleFilter"
        >搜索</el-button
      >
      <el-button
        class="filter-item"
        type="primary"
        @click="checkDialogFormVisible(true, {})"
        >新增</el-button
      >
    </div>

    <div style="margin-bottom: 5px">设备功能数量:{{ total }}</div>

    <el-table
      v-loading="listLoading"
      :data="list"
      element-loading-text="Loading"
      size="small"
      border
      highlight-current-row
    >
      <el-table-column
        align="center"
        prop="id"
        label="ID"
        width="95"
      ></el-table-column>
      <el-table-column
        label="名称"
        width="120"
        align="center"
        prop="name"
      ></el-table-column>
      <el-table-column label="类别" width="100" align="center" prop="type">
        <template slot-scope="scope">
          {{ scope.row.type.name }}
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        prop="created_at"
        label="创建时间"
        width="200px"
      >
        <template slot-scope="scope">
          <i class="el-icon-time" />
          <span>{{ formatApiDate(scope.row.created_at) }}</span>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        prop="created_at"
        label="修改时间"
        width="200px"
      >
        <template slot-scope="scope">
          <i class="el-icon-time" />
          <span>{{ formatApiDate(scope.row.updated_at) }}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="操作"
        align="center"
        class-name="small-padding fixed-width"
      >
        <template slot-scope="scope">
          <el-button
            type="primary"
            size="mini"
            @click="checkDialogFormVisible(true, scope.row)"
            >编辑</el-button
          >
          <!--          <el-button-->
          <!--            type="success"-->
          <!--            size="mini"-->
          <!--            @click="functionVisibleFun(true, scope.row)"-->
          <!--            >设置设备指令</el-button-->
          <!--          >-->
          <el-button
            type="warning"
            size="mini"
            @click="pricingVisibleFun(true, scope.row)"
            >定价设置</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <div class="pagination-container">
      <el-pagination
        :current-page="listQuery.page"
        :page-sizes="[10, 20, 30, 50, 100]"
        :page-size="listQuery.limit"
        :total="total"
        background
        layout="total, sizes, prev, pager, next, jumper"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>

    <!--    编辑和新增-->
    <el-dialog
      :title="DIALOG_TITLE_TYPE[dialogStatus]"
      :visible.sync="dialogFormVisible"
      :close-on-click-modal="false"
      destroy-on-close
    >
      <el-form
        ref="form"
        :rules="rules"
        :model="form"
        label-position="left"
        label-width="150px"
        style="width: 80%; margin-left:50px;"
      >
        <el-form-item label="名称" prop="name">
          <el-input v-model="form.name" placeholder="请输入" />
        </el-form-item>

        <el-form-item label="设备类别" prop="type">
          <el-select
            v-model="form.typeId"
            placeholder="请选择类别"
            style="width: 100%"
            class="filter-item"
            clearable
          >
            <el-option
              v-for="item in typeOption"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="checkDialogFormVisible(false)">取消</el-button>
        <el-button type="primary" @click="saveData" :loading="btnLoading"
          >确定</el-button
        >
      </div>
    </el-dialog>

    <!--    设置设备指令-->
    <el-dialog
      title="设置设备指令"
      :visible.sync="functionVisible"
      :close-on-click-modal="false"
      destroy-on-close
    >
      <FunctionDialog :typeProp="settingObj" />
      <div slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          @click="functionVisibleFun(false, {})"
          :loading="btnLoading"
          >确定</el-button
        >
      </div>
    </el-dialog>

    <!--    定价设置-->
    <el-dialog
      title="定价设置"
      :visible.sync="pricingVisible"
      :close-on-click-modal="false"
      destroy-on-close
    >
      <PricingDialog :typeProp="settingObj" />
      <div slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          @click="pricingVisibleFun(false, {})"
          :loading="btnLoading"
          >确定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { DIALOG_TITLE_TYPE } from "@/utils/enumUtile";
import { formatApiDate } from "@/utils/utils";
import {
  getDeviceServeList,
  createDeviceServe,
  getEquipmentList
} from "@/api/equipment";
import FunctionDialog from "./components/functionDialog.vue";
import PricingDialog from "./components/pricingDeviceFunctionDialog.vue";

export default {
  computed: {
    DIALOG_TITLE_TYPE() {
      return DIALOG_TITLE_TYPE;
    },
    formatApiDate() {
      return formatApiDate;
    }
  },
  components: { FunctionDialog, PricingDialog },
  filters: {},
  data() {
    return {
      list: null,
      total: 0,
      listLoading: true,
      listQuery: {
        page: 1,
        limit: 10,
        keyword: "",
        typeId: ""
      },
      dialogFormVisible: false, // 新增 or 编辑的弹框
      dialogStatus: "",
      rules: {
        typeId: [
          { required: true, message: "设备类别不能为空", trigger: "change" }
        ],
        name: [{ required: true, message: "名称不能为空", trigger: "change" }]
      },
      form: {
        id: "",
        typeId: "",
        name: ""
      },
      btnLoading: false,
      editObj: {}, // 修改的对象
      functionVisible: false, // 功能设置弹框
      pricingVisible: false, // 定价设置弹框
      settingObj: {}, // 设置的对象
      typeOption: [] // 类型选项
    };
  },
  created() {
    this.getList();
    this.getTypeOption();
  },
  watch: {},
  methods: {
    handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },
    handleSizeChange(val) {
      this.listQuery.limit = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.listQuery.page = val;
      this.getList();
    },
    /**
     * 查询设备类型
     */
    getTypeOption() {
      const params = {
        page: 1,
        limit: 9999
      };
      getEquipmentList(params).then(resp => {
        this.typeOption = resp.data.data;
      });
    },
    /**
     * 查询设备功能列表
     */
    getList() {
      this.listLoading = true;
      const form = this.listQuery;
      const params = {
        ...form
      };
      getDeviceServeList(params)
        .then(response => {
          this.list = response.data.data;
          this.total = response.data.total;
        })
        .finally(() => {
          this.listLoading = false;
        });
    },

    /**
     * 切换状态
     * @param status
     * @param editObj
     */
    checkDialogFormVisible(status, editObj = {}) {
      this.dialogFormVisible = status;
      this.editObj = editObj;
      this.dialogStatus =
        editObj && Object.keys(editObj).length !== 0 ? "UPDATE" : "CREATE";
      if (editObj && Object.keys(editObj).length !== 0) {
        this.form.id = editObj.id;
        this.form.typeId = editObj.type_id;
        this.form.name = editObj.name;
      } else {
        this.form.id = "";
        this.form.typeId = "";
        this.form.name = "";
        this.$refs.form.resetFields();
      }
    },

    /**
     * 确认添加还是编辑
     */
    saveData() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          const params = this.form;
          this.btnLoading = true;
          createDeviceServe(params)
            .then(() => {
              this.getList();
              this.checkDialogFormVisible(false, {});
              this.$notify({
                title: "成功",
                message: "保存成功",
                type: "success",
                duration: 2000
              });
            })
            .finally(() => {
              this.btnLoading = false;
            });
        }
      });
    },
    /**
     * 切换功能设置
     * @param status
     * @param row
     */
    functionVisibleFun(status, row = {}) {
      this.settingObj = row;
      this.functionVisible = status;
    },
    /**
     * 定价功能设置
     * @param status
     * @param row
     */
    pricingVisibleFun(status, row = {}) {
      this.settingObj = row;
      this.pricingVisible = status;
    }
  }
};
</script>
<style scoped lang="scss"></style>
