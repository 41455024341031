<template>
  <el-skeleton style="width: 100%" :loading="tabLoading" animated>
    <el-descriptions
      v-for="(proItem, i) in featureItems"
      :key="i"
      class="margin-top"
      :column="1"
      size="medium"
      border
      :labelStyle="{ width: '100px', textAlign: 'center' }"
    >
      <template slot="extra">
        <el-button
          v-if="!proItem.isEdit"
          type="primary"
          size="small"
          @click.prevent="editFeature(i, true)"
          >编辑</el-button
        >
        <el-button
          v-else
          type="primary"
          size="small"
          @click.prevent="saveFeature(i)"
          :loading="proItem.butLoading"
          >保存</el-button
        >
        <!--        <el-popconfirm-->
        <!--          title="确定要删除该数据吗？"-->
        <!--          @confirm="deleteFeature(i)"-->
        <!--          style="margin: 0 8px;"-->
        <!--        >-->
        <!--          <el-button-->
        <!--            slot="reference"-->
        <!--            type="danger"-->
        <!--            size="small"-->
        <!--            :disabled="proItem.isEdit"-->
        <!--            >删除</el-button-->
        <!--          >-->
        <!--        </el-popconfirm>-->
      </template>
      <el-descriptions-item>
        <template slot="label">
          <i class="el-icon-s-operation"></i>
          模式
        </template>
        <el-form ref="modeForm" label-position="left" label-width="150px">
          <el-row
            :gutter="10"
            style="margin: 6px 0;"
            v-for="(item, index) in proItem.data.command_code"
            :key="item.id"
          >
            <el-col :span="10">
              <el-input
                v-model="item.name"
                placeholder="请输入名称"
                v-if="proItem.isEdit"
                :size="mediumType"
              />
              <span v-else>模式名称：{{ item.name }}</span>
            </el-col>
            <el-col :span="10">
              <el-input
                v-model="item.code"
                placeholder="请输入编码"
                v-if="proItem.isEdit"
                :size="mediumType"
              />
              <span v-else>模式编码：{{ item.code }}</span>
            </el-col>
            <el-col
              :span="4"
              style="text-align: right;"
              v-show="proItem.isEdit"
            >
              <el-button
                type="danger"
                size="small"
                style="margin-top: 2px;"
                @click.prevent="removeItem(i, index)"
                >删除</el-button
              >
            </el-col>
          </el-row>
          <el-row style="margin: 6px 0;" :gutter="10">
            <el-col :span="20">
              <el-button
                type="success"
                size="mini"
                style="width: 100%"
                @click.prevent="addItem(i)"
                v-show="proItem.isEdit"
                >添加模式</el-button
              >
            </el-col>
          </el-row>
        </el-form>
      </el-descriptions-item>
    </el-descriptions>
    <el-row style="margin: 20px 0;">
      <el-col :span="24">
        <el-button
          type="primary"
          style="width: 100%"
          @click.prevent="addFeatureItem"
          >添加指令</el-button
        >
      </el-col>
    </el-row>
  </el-skeleton>
</template>
<script>
import {
  getDeviceServeCommand, // TODO 需要改
  // deleteFunc,
  setDeviceServeCommand
} from "@/api/equipment";
export default {
  props: {
    typeProp: Object
  },
  watch: {
    typeProp(newVal) {
      this.typeObj = newVal;
      this.getList();
    }
  },
  data() {
    return {
      typeObj: this.typeProp,
      tabLoading: false,
      featureItems: [],
      mediumType: "medium"
    };
  },
  created() {
    this.getList();
  },
  methods: {
    getList() {
      const typeObj = this.typeObj;
      if (!typeObj || Object.keys(typeObj).length === 0) {
        return;
      }
      const params = {
        id: typeObj.id
      };
      this.tabLoading = true;
      getDeviceServeCommand(params)
        .then(resp => {
          const arrList = [];
          const data = resp.data || [];
          if (data && data.length !== 0) {
            const command_code = data.command_code
              ? JSON.parse(data.command_code)
              : [];
            command_code.map((item, key) => {
              arrList.push({
                data: {
                  id: typeObj.id,
                  name: item.name,
                  command_code: item.commands
                },
                nextId: key,
                isEdit: false,
                butLoading: false
              });
            });
          }
          this.featureItems = arrList;
        })
        .finally(() => {
          this.tabLoading = false;
        });
    },
    removeItem(propIndex, index) {
      this.featureItems[propIndex].data.command_code.splice(index, 1);
    },
    addItem(propIndex) {
      this.featureItems[propIndex].data.command_code.push({
        name: "",
        code: ""
      });
    },

    /**
     * 添加 prop item 的框架
     */
    addFeatureItem() {
      const typeObj = this.typeObj;
      const addItem = {
        data: {
          id: typeObj.id,
          command_code: [{ name: "", code: "" }]
        },
        nextId: 2,
        isEdit: true,
        butLoading: false
      };
      this.featureItems.push(addItem);
    },
    /**
     * 编辑 prop item
     * @param propIndex
     * @param status
     */
    editFeature(propIndex, status) {
      this.featureItems[propIndex].isEdit = status;
    },
    /**
     * 删除 prop item
     * @param propIndex
     */
    // deleteFeature(propIndex) {
    //   const featureItem = this.featureItems[propIndex];
    //   const params = {
    //     id: featureItem.data.id
    //   };
    //   deleteFunc(params)
    //     .then(resp => {
    //       if (resp.code === 0) {
    //         this.$notify({
    //           title: "成功",
    //           message: resp.data,
    //           type: "success",
    //           duration: 2000
    //         });
    //       } else {
    //         this.$notify({
    //           title: "提示",
    //           message: resp.data,
    //           type: "warning",
    //           duration: 2000
    //         });
    //       }
    //     })
    //     .finally(() => {
    //       this.getList();
    //     });
    // },
    /**
     * 确认 添加 prop item
     * @param propIndex
     */
    saveFeature(propIndex) {
      this.featureItems[propIndex].butLoading = true;
      const featureItem = this.featureItems[propIndex];
      const params = {
        id: featureItem.data.id,
        commandCode: JSON.stringify([
          { commands: featureItem.data.command_code }
        ])
      };
      setDeviceServeCommand(params)
        .then(resp => {
          if (resp.code === 0) {
            this.$notify({
              title: "成功",
              message: resp.data,
              type: "success",
              duration: 2000
            });
          } else {
            this.$notify({
              title: "提示",
              message: resp.data,
              type: "warning",
              duration: 2000
            });
          }
          this.getList();
        })
        .finally(() => {
          this.featureItems[propIndex].butLoading = false;
          this.editFeature(propIndex, false);
        });
    }
  },
  destroyed() {
    this.featureItems = [];
    this.typeObj = [];
    this.tabLoading = false;
    this.featureItems = [];
    this.mediumType = "medium";
  }
};
</script>
<style scoped lang="scss">
.el-descriptions {
  margin-bottom: 18px;
}
</style>
